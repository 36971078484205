import React from "react";
import dummyImg from "../assets/images/dummy.jpg";
import info from "../assets/svg/info.svg";
import { CartBucket, CloseIcon, ProductNotFound } from "../assets/cart/svg";

const CartDrawer = ({
  setOpen,
  cartData,
  handleEditCartQuantity,
  handleDeleteCartItems,
  handleCheckout,
}) => {
  const totalItems =
    cartData?.data?.items?.reduce((acc, item) => acc + item.quantity, 0) || 0;

  const getNewPrice = (currentPrice, price) => {
    if (currentPrice > price) {
      return `Price of this product has been increased by the seller by $${
        currentPrice - price
      }.`;}
  };

  return (
    <section className="fixed top-0 right-0 z-50 h-full w-full max-w-md bg-white shadow-lg">
      <header className="flex items-center justify-between border-b p-4">
        <div className="flex items-center text-primary">
          <CartBucket />
          <span className="ml-2">{totalItems || 0} Items</span>
        </div>
        <button
          onClick={() => setOpen(false)}
          className="text-gray-600 hover:bg-primary p-2 rounded-full hover:text-white"
        >
          <CloseIcon />
        </button>
      </header>
      <div className="grow pb-18 max-w-md">
        {cartData?.data?.items?.length > 0 ? (
          <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
            {cartData?.data?.items?.map((item, index) => (
              <div
                key={index}
                className="flex items-center justify-between border-b border-solid border-gray-100 border-opacity-75 px-4 py-4 text-sm sm:px-6"
              >
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <div className="flex overflow-hidden flex-col-reverse items-center w-8 h-24 bg-gray-100 rounded-full">
                      <button
                        onClick={() => handleEditCartQuantity(item, "decrease")}
                        className="cursor-pointer p-2 hover:text-white duration-200 hover:bg-primary-hover focus:outline-0"
                      >
                        <span className="sr-only">minus</span>
                        <i className="ri-subtract-line"></i>
                      </button>
                      <div className="flex flex-1 items-center justify-center px-3 text-sm font-semibold !px-0">
                        {item.quantity}
                      </div>
                      <button
                        onClick={() => handleEditCartQuantity(item, "increase")}
                        className="cursor-pointer p-2 hover:text-white duration-200 hover:bg-primary-hover focus:outline-0"
                        title=""
                      >
                        <span className="sr-only">plus</span>
                        <i className="ri-add-line"></i>
                      </button>
                    </div>
                  </div>
                  <div className="relative mx-4 flex h-10 w-10 shrink-0 items-center justify-center overflow-hidden bg-gray-100 sm:h-16 sm:w-16">
                    <img
                      alt={item.productName}
                      loading="lazy"
                      src={item.productImage || dummyImg}
                    />
                  </div>
                  <div>
                    <h3 className="font-bold truncate-text">
                      {item.productName}
                    </h3>
                    <p className="my-2.5 font-semibold text-primary">
                      {item?.price !== item?.previousPrice ? (
                        <del className="text-sm text-black">${item?.previousPrice}</del>
                      ) : null}{" "}
                      ${item?.price}
                    </p>
                    <p className="text-xs flex gap-1">
                      {getNewPrice(item?.price, item.previousPrice) && (
                        <img src={info} className="w-3 h-3 mt-1" alt="" />
                      )}
                      {getNewPrice(item?.price, item.previousPrice)}
                    </p>
                    <span className="text-xs">{item.quantity} X 1 pcs</span>
                  </div>
                </div>
                <div className="flex items-center h-full">
                  <span className="font-bold ml-4 mr-4">${item.totalPrice}</span>
                  <button
                    onClick={() => handleDeleteCartItems(item.productId)}
                    className="flex h-7 w-7 shrink-0 items-center justify-center rounded-full transition-all duration-200 hover:bg-gray-100 hover:text-red-600 focus:bg-gray-100 focus:text-red-600 focus:outline-0 ml-3 -mr-2 rtl:mr-3 rtl:-ml-2"
                  >
                    <span className="sr-only">close</span>
                    <i className="ri-delete-bin-line"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex h-full flex-col absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 items-center justify-center">
            <ProductNotFound />
            <h4 className="mt-6 text-base font-semibold">No products found</h4>
          </div>
        )}
      </div>
      <footer className="fixed bottom-0 right-0 z-10 w-full max-w-md bg-white px-6 py-5">
        <button
          onClick={handleCheckout}
          className="flex h-12 w-full justify-between rounded-full bg-primary p-1 text-sm font-bold shadow-700 hover:bg-primary-hover focus:bg-primary-hover focus:outline-0 md:h-14"
        >
          <span className="flex h-full flex-1 items-center px-5 text-white">
            Checkout
          </span>
          <span className="flex h-full shrink-0 items-center rounded-full bg-white px-5 text-primary">
            ${cartData?.data?.totalCartValue || 0}
          </span>
        </button>
      </footer>
    </section>
  );
};

export default CartDrawer;
