import React from "react";
import { Form } from "antd";
import CustomInput from "../../components/Forms/input";
import { useTranslation } from "react-i18next";
import { login } from "../../redux/reducers/authSlice";
import useAxios from "../../hooks/useAxios";
import { REGISTER } from "../../contstants/apis";
import { Link } from "react-router-dom";
import Logo from "../../components/Logo";
import CustomButton from "../../components/CustomButton";

const SignUp = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation("SignUpPage");
  const {makeRequest} = useAxios();

  const handleLogin = (values)=>{
    const formData = {
      name : values.name,
      email : values.email,
      mobileNumber: values.mobileNumber,
      password: values.password,
      userProfileImageUrl : 'https://th.bing.com/th?id=OIP.kj5OOtojtJhhO2XgghLgCwHaIZ&w=234&h=266&c=8&rs=1&qlt=90&o=6&dpr=1.3&pid=3.1&rm=2'
    }

    makeRequest({
      url : REGISTER, 
      method : "POST", 
      data : formData, 
      reduxAction : login , 
      route : '/auth/signin' , 
      isToastVisible : false
  })
  }
  return (
    <div className="flex justify-center items-center bg-light sm:bg-gray-100 h-screen">
      <div className="m-auto w-full max-w-[420px] rounded bg-white p-5 sm:p-8 sm:shadow">
        <Form
          form={form}
          name="login-form"
          layout="vertical"
          initialValues={{
            // firstName: "",
            // lastName: "",
            name : '',
            email: "",
            mobileNumber: '',
            password: "",
          }}
         onFinish={handleLogin}
        >
          <div className="flex justify-center">
           <Logo/>
          </div>
          <p className="mb-4 text-center">{t("signUpHeader")}</p>

          <Form.Item
            name="name"
            label={<span className="font-semibold">{t("firstName")}</span>}
            rules={[{ required: true, message: "Name is required" }]}
            className="w-full mb-0 mt-4"
          >
            <CustomInput placeholder="Enter your name" />
          </Form.Item>

          <Form.Item
            name="email"
            label={<span className="font-semibold">{t("email")}</span>}
            rules={[{ required: true, message: "Email is required" }]}
            className="w-full mb-0 mt-4"
          >
            <CustomInput placeholder="Enter your email" />
          </Form.Item>
          <Form.Item
            name="mobileNumber"
            label={<span className="font-semibold">{t("mobileNumber")}</span>}
            rules={[{ required: true, message: "Mobile Number is required" }]}
            className="w-full mb-0 mt-4"
          >
            <CustomInput placeholder="Enter your Mobile Number" />
          </Form.Item>
          {/* <label>Email</label> */}
          <Form.Item
            className="mt-4"
            rules={[{ required: true, message: "Password is required" }]}
            label={<span className="font-semibold">{t("password")}</span>}
            name="password"
          >
            <CustomInput type="password" placeholder="Enter your Password" />
          </Form.Item>
          {/* <button className="bg-[#009F7F] text-white rounded-lg px-4 py-2 w-full">
            {t("register")}
          </button> */}
          <CustomButton htmlType="submit" title={t("register")} size={"large"} className="w-full text-lg font-medium"/>
          <p className="text-end mt-1">
            <Link href="#" className="text-[#009F7F]-500">
              {t("forgotPassword")}
            </Link>
          </p>
          
          <p className="text-center mt-4">
            {t("alreadyHaveAccount")}{" "}
            <Link to="/signin" className="text-green-500">
              {t("login")}
            </Link>
          </p>
        </Form>
      </div>
    </div>
  );
};

export default SignUp;
