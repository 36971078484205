import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useDispatch, useSelector } from "react-redux";
import { setOrderData } from "../../redux/reducers/products";
import {
  fetchBillingAddress,
  fetchOrderDetails,
  fetchOrderDetailsById,
  fetchShippingAddress,
} from "../../services/order.service";
import { HomeIcon } from "../../assets/cart/svg";
import { useTranslation } from "react-i18next";

const OrderStatus = () => {
  const { makeRequest } = useAxios();
  const dispatch = useDispatch();
  const { orderData } = useSelector((state) => state.products);
  const { user } = useSelector((state) => state.authSlice);
  const [orderDataById, setOrderDataById] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);
  const [shippingAddress, setShippingAddress] = useState(null);
  const { t } = useTranslation("OrderDetail");
  const userId = user?.data?.data?.id;

  const handleShippingAddress = (id) => {
    fetchShippingAddress(makeRequest, id, setShippingAddress);
  };
  const handleBillingAddress = (id) => {
    fetchBillingAddress(makeRequest, id, setBillingAddress);
  };

  const handleOrderDetailsById = (orderId) => {
    fetchOrderDetailsById(
      makeRequest,
      orderId,
      setOrderDataById,
      handleShippingAddress,
      handleBillingAddress
    );
  };

  useEffect(() => {
    fetchOrderDetails(makeRequest, userId, setOrderData, dispatch);
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "placed":
        return { bg: "bg-yellow-100", text: "text-yellow-600" };
      case "shipped":
        return { bg: "bg-blue-100", text: "text-blue-600" };
      case "completed":
        return { bg: "bg-green-100", text: "text-green-600" };
      case "cancelled":
        return { bg: "bg-red-100", text: "text-red-600" };
      default:
        return { bg: "bg-gray-100", text: "text-gray-600" };
    }
  };

  useEffect(() => {
    if (orderData?.data?.[0]?.id) {
      handleOrderDetailsById(orderData?.data[0].id);
    }
  }, [orderData]);

  const columns = [
    {
      title: "Item",
      dataIndex: "productName",
      key: "productName",
      render: (text, record) => (
        <div className="flex items-center">
          <div className="relative flex h-16 w-16 shrink-0 overflow-hidden rounded">
            <img
              alt={record.productName || "Product"}
              loading="lazy"
              className="h-full w-full object-contain"
              src={record.productImage}
            />
          </div>
          <div className="flex flex-col overflow-hidden ml-4">
            <span className="inline-block text-sm truncate hover:text-primary hover:underline truncate">
              {record.productName || "Unnamed Product"}
            </span>
          </div>
        </div>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => `$${price}`,
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (totalPrice) => `$${totalPrice}`,
    },
  ];

  console.log("orderDataById", orderDataById);

  const data = orderDataById?.data?.items?.map((item, index) => ({
    key: index,
    productName: item.productName,
    productImage: item.productImage,
    quantity: item.quantity,
    price: item.price,
    totalPrice: item.totalPrice,
  }));

  return (
    <div className="p-4 sm:p-8">
      <div className="mx-auto w-full max-w-screen-lg">
        <div className="mb-5">
          <Link
            className="inline-flex items-center gap-2 text-sm font-semibold text-primary hover:text-primary-hover"
            to="/"
          >
            <HomeIcon />
            {t("backHome")}
          </Link>
        </div>
        <div className="relative overflow-hidden rounded border shadow-sm">
          <div className="bg-[#F7F8FA] lg:px-11 lg:py-5 p-6">
            <div className="flex flex-col flex-wrap items-center justify-between mb-0 text-base font-bold gap-x-8 sm:flex-row lg:flex-nowrap">
              <div className="order-2 grid w-full grid-cols-1 gap-6 xs:flex-nowrap sm:order-1 max-w-full basis-full justify-between md:grid-cols-2">
                <div className="flex items-center gap-3">
                  <span className="block text-xs shrink-0 grow-0 basis-auto xs:text-base lg:inline-block">
                    {t("orderStatus")} :
                  </span>
                  <div className="w-full lg:w-auto">
                    <span
                      className={`px-3 py-1 rounded-full bg-opacity-[.15] min-h-[2rem] items-center ${
                        getStatusColor(orderDataById?.data?.orderStatus).bg
                      } ${
                        getStatusColor(orderDataById?.data?.orderStatus).text
                      } justify-center text-[9px] !leading-none xs:text-sm inline-flex`}
                    >
                      {orderDataById?.data?.orderStatus}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-3 md:ml-auto">
                  <span className="block text-xs shrink-0 grow-0 basis-auto xs:text-base lg:inline-block">
                    {t("paymentStatus")} :
                  </span>
                  <div className="w-full lg:w-auto">
                    <span className="px-3 py-1 rounded-full text-white bg-primary bg-opacity-[.15] !text-primary min-h-[2rem] items-center justify-center truncate whitespace-nowrap text-[9px] !leading-none xs:text-sm inline-flex">
                      {orderDataById?.data?.paymentStatus}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 sm:p-8 lg:p-12">
            <div className="mb-6 grid gap-4 sm:grid-cols-2 md:mb-12 lg:grid-cols-4">
              <div className="rounded border border-border-200 px-5 py-4 shadow-sm">
                <h3 className="mb-2 text-sm font-semibold">
                  {t("orderNumber")}
                </h3>
                <p className="text-sm ">{orderDataById?.data?.orderNumber}</p>
              </div>
              <div className="rounded border border-border-200 px-5 py-4 shadow-sm">
                <h3 className="mb-2 text-sm font-semibold">{t("date")}</h3>
                <p className="text-sm">
                  {formatDate(orderDataById?.data?.createdOn)}
                </p>
              </div>
              <div className="rounded border border-border-200 px-5 py-4 shadow-sm">
                <h3 className="mb-2 text-sm font-semibold">{t("total")}</h3>
                <p className="text-sm">
                  ${orderDataById?.data?.totalOrderValue}
                </p>
              </div>
              <div className="rounded border border-border-200 px-5 py-4 shadow-sm">
                <h3 className="mb-2 text-sm font-semibold">
                  {t("paymentMethod")}
                </h3>
                <p className="text-sm ">{orderDataById?.data?.paymentStatus}</p>
              </div>
            </div>
            {/* <ProgressBar /> */}
            <div className="flex flex-col lg:flex-row">
              <div className="mb-12 w-full lg:mb-0 lg:w-1/2 lg:pr-3 rtl:lg:pl-3">
                <h2 className="mb-6 text-xl font-bold">{t("totalAmount")}</h2>
                <div>
                  <p className="mt-5 flex ">
                    <strong className="w-5/12 text-sm font-semibold sm:w-4/12">
                      {t("subTotal")} :
                    </strong>
                    <span className="w-7/12 text-sm pl-4 rtl:pr-4 sm:w-8/12 ">
                      {orderDataById?.data?.subTotal}
                    </span>
                  </p>
                  <p className="mt-5 flex ">
                    <strong className="w-5/12 text-sm font-semibold sm:w-4/12">
                      {t("tax")}:
                    </strong>
                    <span className="w-7/12 text-sm pl-4 rtl:pr-4 sm:w-8/12 ">
                      {orderDataById?.totalTax || 0}
                    </span>
                  </p>
                  <p className="mt-5 flex ">
                    <strong className="w-5/12 text-sm font-semibold sm:w-4/12">
                      {t("total")} :
                    </strong>
                    <span className="w-7/12 text-sm pl-4 rtl:pr-4 sm:w-8/12">
                      ${orderDataById?.data?.totalOrderValue}
                    </span>
                  </p>
                </div>
              </div>
              <div className="w-full lg:w-1/2 lg:pl-3 rtl:lg:pr-3">
                <h2 className="mb-6 text-xl font-bold">{t("orderDetails")}</h2>
                <div>
                  <p className="mt-5 flex ">
                    <strong className="w-4/12 text-sm font-semibold">
                      {t("name")} :
                    </strong>
                    <span className="w-8/12 text-sm pl-4 rtl:pr-4 ">
                      {orderDataById?.data?.customerFullName}
                    </span>
                  </p>
                  <p className="mt-5 flex ">
                    <strong className="w-4/12 text-sm font-semibold">
                      {t("contactNumber")} :
                    </strong>
                    <span className="w-8/12 text-sm pl-4 rtl:pr-4 ">
                      {orderDataById?.data?.customerContactNoPrimary}
                    </span>
                  </p>
                  <p className="mt-5 flex ">
                    <strong className="w-4/12 text-sm font-semibold">
                      {t("totalItem")} :
                    </strong>
                    <span className="w-8/12 text-sm pl-4 rtl:pr-4 ">
                      {orderDataById?.data?.items?.length} {t("items")}
                    </span>
                  </p>
                  <p className="mt-5 flex ">
                    <strong className="w-4/12 text-sm font-semibold">
                      {t("deliveryItem")}:
                    </strong>
                    <span className="w-8/12 text-sm pl-4 rtl:pr-4 ">
                      {orderDataById?.data?.deliverySchedule ?? "-"}
                    </span>
                  </p>
                  <p className="mt-5 flex ">
                    <strong className="w-4/12 text-sm font-semibold">
                      {t("shippingAddress")} :
                    </strong>
                    <span className="w-8/12 text-sm pl-4 rtl:pr-4 ">
                      {shippingAddress?.data?.address}
                    </span>
                  </p>
                  <p className="mt-5 flex ">
                    <strong className="w-4/12 text-sm font-semibold">
                      {t("billingAddress")} :
                    </strong>
                    <span className="w-8/12 text-sm pl-4 rtl:pr-4">
                      {billingAddress?.data?.address}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-12">
              <Table
                scroll={{ x: "max-content" }}
                columns={columns}
                pagination={false}
                dataSource={data}
                className="rounded bg-white shadow"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderStatus;
