import React, { useEffect } from "react";
import { Form } from "antd";
import CustomInput from "../../components/Forms/input";
import { useTranslation } from "react-i18next";
import { login } from "../../redux/reducers/authSlice";
import useAxios from "../../hooks/useAxios";
import { LOGIN } from "../../contstants/apis";
import { Link } from "react-router-dom";
import CustomButton from "../../components/CustomButton";

const SignIn = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation("SignInPage");
  const { makeRequest } = useAxios();
 
  const handleLogin = (values) => {
    const formData = {
      email: values.email,
      password: values.password
    }
    makeRequest({
      url : `${LOGIN}`, 
      method : "POST", 
      data : formData, 
      reduxAction : login, 
      onSuccessCallback : (res)=>{
      if(res?.code===200){
        const timestamp =  new Date().getTime();
        localStorage.setItem('lastInteraction', timestamp);
      }
    },
     route : '/', 
     isToastVisible : true
    })
  };

  return (
    <div className="flex justify-center items-center bg-light sm:bg-gray-100 h-screen">
      <div className="m-auto w-full max-w-[420px] rounded bg-white p-5 sm:p-8 sm:shadow">
        <Form
          form={form}
          name="login-form"
          layout="vertical"
          initialValues={{
            email: "",
            password: "",
          }}
          onFinish={handleLogin}
        >
          <div className="flex justify-center">
            <h1 className='text-2xl text-primary font-semibold text-center'>
              <span className='text-red-700 text-4xl'>E</span>factura</h1>
          </div>
          <p className="mb-4 text-center">{t("loginHeader")}</p>
          <Form.Item
            name="email"
            label={<span className="font-semibold">{t("email")}</span>}
            rules={[{ required: true, message: "Email is required" }]}
            className="rounded"
          >
            <CustomInput placeholder="Enter your email" />
          </Form.Item>
          <Form.Item
            className="mt-4"
            rules={[{ required: true, message: "Password is required" }]}
            label={<span className="font-semibold">{t("password")}</span>}
            name="password"
          >
            <CustomInput type="password" placeholder="Enter your Password" />
          </Form.Item>
          {/* <button
            type="submit"
            className="bg-[#009F7F] text-white rounded-lg px-4 py-2 w-full"
          >
            {t("login")}
          </button> */}
          <CustomButton htmlType="submit" title={t("login")} size={"large"} className={"w-full text-lg font-medium"}/>
          <p className="text-end mt-1">
            <Link to="#" className="text-[#009F7F]-500">
              {t("forgotPassword")}
            </Link>
          </p>
          <p className="text-center mt-4">
            {t("dontHaveAccount")}{" "}
            <Link to="/signup" className="text-green-500">
              {t("registerNow")}
            </Link>
          </p>
        </Form>
      </div>
    </div>
  );
};

export default SignIn;
