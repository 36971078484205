// import React, { useState } from "react";
// import { Link, Navigate, useNavigate } from "react-router-dom";

// const Sidebar = ({
//   categoryData,
//   selectedSubcategory,
//   setSelectedSubcategory,
//   selectedCategory,
//   setSelectedCategory,
//   isSideBarOpen,
//   setIsSideBarOpen,
//   handleCategoryClick,
//   handleSubcategoryClick,
//   expandedCategories,
//   reset
// }) => {
//   // const [isSideBarOpen, setIsSideBarOpen] = useState(false);
//   // const [expandedCategories, setExpandedCategories] = useState(null);
//   //   const [selectedCategory, setSelectedCategory] = useState(null);
//   //   const [selectedSubcategory, setSelectedSubcategory] = useState(null);
//   console.log("categoryData>>>>>>>>>>", categoryData);
//   // console.log("selectedSubcategory", selectedSubcategory);
//   // console.log("selectedCategory", selectedCategory);

//   // const categoriesData = [
//   //   {
//   //     id: "66e5d831cc77d9684a6e3d50",
//   //     categoryName: "electronics",
//   //     subCategories: [
//   //       {
//   //         id: "191f1d48002-1fe4",
//   //         subCategoryName: "mobile",
//   //       },
//   //     ],
//   //   },
//   //   {
//   //     id: "66e5e925cc77d9684a6e3d51",
//   //     categoryName: "Home",
//   //     subCategories: [
//   //       {
//   //         id: "191f216bbcb-6ee2",
//   //         subCategoryName: "grocery",
//   //       },
//   //       {
//   //         id: "191f216bbcb-81d8",
//   //         subCategoryName: "furniture",
//   //       },
//   //     ],
//   //   },
//   // ];

//   // console.log("categoriesData", categoriesData);

//   // const handleSidebarToggle = () => {
//   //   setIsSideBarOpen(!isSideBarOpen);
//   // };

//   // const handleCategoryClick = (category) => {
//   //   setSelectedCategory(category);
//   //   setExpandedCategories(expandedCategories === category?.id ? null : category?.id);
//   //   setSelectedSubcategory(null);
//   // };

//   // const handleSubcategoryClick = (subcategory) => {
//   //   setSelectedSubcategory(subcategory);
//   //   setSelectedCategory(null);
//   // };

//   return (
//     <>
//       {/* <aside className="hidden h-full bg-white lg:sticky xl:block xl:sticky top-0 left-0 xl:w-72 lg:top-22"> */}
//       <div className="max-h-full grow overflow-hidden">
//         <div
//           className="max-h-screen w-full p-5"
//           // style={{ height: "calc(100vh - 5.35rem)" }}
//         >
//           <div className="flex items-center justify-between">
//             <h3 className="font-bold">Category Filter</h3>
//             {(selectedCategory ||selectedSubcategory) && <div onClick={reset} className="text-primary font-semibold cursor-pointer ">Reset</div>}
//             {/* <button
//               onClick={reset}
//               className="flex h-7 w-7 items-center justify-center rounded-full bg-gray-200 transition-all duration-200 hover:bg-primary hover:text-white"
//             >
//               <span className="sr-only">reset</span>
              
//             </button> */}
//           </div>
//           <div className="">
//             <ul className="text-xs xl:py-4">
//               {categoryData?.map((category) => (
//                 <>
//                   <li
//                     className={`rounded-md py-1 duration-200 ${
//                       selectedCategory?.id === category.id ? "text-primary" : ""
//                     }`}
//                     style={{ backgroundColor: "rgb(255, 255, 255)" }}
//                     key={category.id}
//                   >
//                     <button
//                       className="flex w-full justify-between gap-4 items-center py-2 font-semibold outline-none transition-all ease-in-expo focus:outline-0 focus:ring-0 ltr:text-left rtl:text-right text-sm"
//                       onClick={() => {
//                         // toggleDropdown(category.id);
//                         handleCategoryClick(category);
//                       }}
//                     >
//                       <div className="flex items-center ">
//                         {/* <span className="flex h-5 w-5 items-center justify-center mr-4">
//                           {category.icon}
//                         </span> */}
//                         <span className="ml-1 text-left">
//                           {category.categoryName}
//                         </span>
//                       </div>
//                       <span className="ml-auto mr-4 transition-transform transform duration-300 ease-in-out">
//                         <svg
//                           xmlns="http://www.w3.org/2000/svg"
//                           fill="none"
//                           viewBox="0 0 24 24"
//                           stroke="currentColor"
//                           className="h-3 w-3"
//                         >
//                           <path
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                             strokeWidth="2"
//                             d={
//                               expandedCategories !== category.id
//                                 ? "M19 9l-7 7-7-7"
//                                 : "M5 15l7-7 7 7"
//                             }
//                           />
//                         </svg>
//                       </span>
//                     </button>
//                   </li>
//                   <li>
//                     <ul className="text-xs ml-1">
//                       {expandedCategories === category?.id &&
//                         category.subCategories.length > 0 &&
//                         category.subCategories.map((subcategory) => (
//                           <li
//                             className={`rounded-md py-1 ${
//                               selectedSubcategory?.subCategoryName ===
//                               subcategory?.subCategoryName
//                                 ? "text-primary"
//                                 : ""
//                             }`}
//                             style={{ backgroundColor: "rgb(255, 255, 255)" }}
//                             key={subcategory?.id}
//                           >
//                             <button
//                               className="flex w-full items-center py-2 font-semibold outline-none transition-all focus:text-primary focus:outline-0 focus:ring-0 ltr:text-left rtl:text-right text-sm"
//                               onClick={() =>
//                                 handleSubcategoryClick(subcategory)
//                               }
//                             >
//                               {/* <span className="flex h-5 w-5 items-center justify-center mr-4">
//                                 {category.icon}
//                               </span> */}
//                               <span>{subcategory?.subCategoryName}</span>
//                             </button>
//                           </li>
//                         ))}
//                     </ul>
//                   </li>
//                 </>
//               ))}
//             </ul>
//           </div>
//         </div>
//       </div>
//       {/* </aside> */}
//       {/* <div className="sticky z-20 flex h-14 items-center justify-between border-t border-b border-border-200 bg-white py-3 px-5 md:h-16 lg:px-6 xl:hidden top-[59px] lg:top-[63px]">
//         <button
//           onClick={handleSidebarToggle}
//           className="flex gap-2 h-8 items-center rounded border border-border-200 bg-gray-100 bg-opacity-90 py-1 px-3 text-sm font-semibold text-heading transition-colors duration-200 hover:border-primary-hover hover:bg-primary hover:text-white focus:border-primary-hover focus:bg-primary focus:text-white focus:outline-0 md:h-10 md:py-1.5 md:px-4 md:text-base"
//         >
//           <svg
//             width="18"
//             height="14"
//             className="ltr:mr-2 rtl:ml-2"
//             viewBox="0 0 18 14"
//           >
//             <path
//               d="M942.581,1295.564H925.419c-.231,0-.419-.336-.419-.75s.187-.75.419-.75h17.163c.231,0,.419.336.419.75S942.813,1295.564,942.581,1295.564Z"
//               transform="translate(-925 -1292.064)"
//               fill="currentColor"
//             ></path>
//             <path
//               d="M942.581,1951.5H925.419c-.231,0-.419-.336-.419-.75s.187-.75.419-.75h17.163c.231,0,.419.336.419.75S942.813,1951.5,942.581,1951.5Z"
//               transform="translate(-925 -1939.001)"
//               fill="currentColor"
//             ></path>
//             <path
//               d="M1163.713,1122.489a2.5,2.5,0,1,0,1.768.732A2.483,2.483,0,0,0,1163.713,1122.489Z"
//               transform="translate(-1158.213 -1122.489)"
//               fill="currentColor"
//             ></path>
//             <path
//               d="M2344.886,1779.157a2.5,2.5,0,1,0,.731,1.768A2.488,2.488,0,0,0,2344.886,1779.157Z"
//               transform="translate(-2330.617 -1769.425)"
//               fill="currentColor"
//             ></path>
//           </svg>
//           Filter
//         </button>
//       </div> */}

//       {
//         <div
//           className={`fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50 p-4 sm:p-0 transition-opacity duration-300 ${
//             isSideBarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
//           }`}
//         >
//           {/* Overlay for background opacity */}
//           <div className="absolute inset-0 bg-black opacity-50"></div>
//           <section
//             className={`fixed top-0 left-0 z-50 h-full w-full max-w-md bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
//               isSideBarOpen ? "translate-x-0" : "-translate-x-full"
//             }`}
//             style={{ width: "100%", maxWidth: "28rem" }}
//           >
//             <div className="flex h-full flex-col">
//               <div className="fixed top-0 z-20 flex w-full max-w-md items-center justify-between border-b border-gray-200 border-opacity-75 bg-white p-5 px-5 md:px-6">
//                 <Link className="inline-flex w-24 md:w-auto" to="/">
//                   {/* <span className="relative h-[2.125rem] w-32 overflow-hidden md:w-[8.625rem]">
//                                         <img alt="Pickbazar" className="object-contain" src={logo} />
//                                     </span> */}
//                   <span className="relative h-[2.125rem] w-32 flex items-center overflow-hidden md:w-[8.625rem]">
//                     {/* <img alt="Pickbazar" className="object-contain" src={logo} /> */}
//                     <h1 className="text-lg text-primary font-semibold text-center">
//                       <span className="text-red-700 text-3xl">E</span>factura
//                     </h1>
//                   </span>
//                 </Link>
//                 <button
//                   onClick={() => setIsSideBarOpen(false)}
//                   className="flex h-7 w-7 items-center justify-center rounded-full bg-gray-200 transition-all duration-200 hover:bg-primary hover:text-white focus:bg-primary focus:text-white focus:outline-0"
//                 >
//                   <span className="sr-only">close</span>
//                   <svg
//                     className="h-2.5 w-2.5"
//                     xmlns="http://www.w3.org/2000/svg"
//                     viewBox="0 0 20 20"
//                     fill="currentColor"
//                   >
//                     <path
//                       fillRule="evenodd"
//                       d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
//                       clipRule="evenodd"
//                     ></path>
//                   </svg>
//                 </button>
//               </div>
//               <div className="pt-20">
//                 <ul className="text-xs xl:py-8 px-5">
//                   {categoryData?.map((category) => (
//                     <>
//                       <li
//                         className={`rounded-md py-1 duration-200 ${
//                           selectedCategory?.id === category.id
//                             ? "text-primary"
//                             : ""
//                         }`}
//                         style={{ backgroundColor: "rgb(255, 255, 255)" }}
//                         key={category.id}
//                       >
//                         <button
//                           className="flex w-full justify-between gap-4 items-center py-2 font-semibold outline-none transition-all ease-in-expo focus:outline-0 focus:ring-0 ltr:text-left rtl:text-right text-sm"
//                           onClick={() => {
//                             // toggleDropdown(category.id);
//                             handleCategoryClick(category);
//                           }}
//                         >
//                           <div className="flex items-center ">
//                             <span className="flex h-5 w-5 items-center justify-center mr-4">
//                               {category.icon}
//                             </span>
//                             <span className="ml-1 text-left">
//                               {category.categoryName}
//                             </span>
//                           </div>
//                           <span className="ml-auto mr-4 transition-transform transform duration-300 ease-in-out">
//                             <svg
//                               xmlns="http://www.w3.org/2000/svg"
//                               fill="none"
//                               viewBox="0 0 24 24"
//                               stroke="currentColor"
//                               className="h-3 w-3"
//                             >
//                               <path
//                                 strokeLinecap="round"
//                                 strokeLinejoin="round"
//                                 strokeWidth="2"
//                                 d={
//                                   expandedCategories !== category.id
//                                     ? "M19 9l-7 7-7-7"
//                                     : "M5 15l7-7 7 7"
//                                 }
//                               />
//                             </svg>
//                           </span>
//                         </button>
//                       </li>
//                       <li>
//                         <ul
//                           className="text-xs ml-1"
//                           // className={`transition-all px-6 duration-300 ease-in-out overflow-hidden ${expandedCategories[category.id]
//                           //     ? 'opacity-100 max-h-40'
//                           //     : 'opacity-0 max-h-0'
//                           //     }`}
//                         >
//                           {expandedCategories === category?.id &&
//                             category.subCategories.length > 0 &&
//                             category.subCategories.map((subcategory) => (
//                               <li
//                                 className={`rounded-md py-1 ${
//                                   selectedSubcategory?.subCategoryName ===
//                                   subcategory?.subCategoryName
//                                     ? "text-primary"
//                                     : ""
//                                 }`}
//                                 style={{
//                                   backgroundColor: "rgb(255, 255, 255)",
//                                 }}
//                                 key={subcategory?.id}
//                               >
//                                 <button
//                                   className="flex w-full items-center py-2 font-semibold outline-none transition-all focus:text-primary focus:outline-0 focus:ring-0 ltr:text-left rtl:text-right text-sm"
//                                   onClick={() =>
//                                     handleSubcategoryClick(subcategory)
//                                   }
//                                 >
//                                   <span className="flex h-5 w-5 items-center justify-center mr-4">
//                                     {/* {category.icon} */}
//                                   </span>
//                                   <span>{subcategory?.subCategoryName}</span>
//                                 </button>
//                               </li>
//                             ))}
//                         </ul>
//                       </li>
//                     </>
//                   ))}
//                 </ul>
//               </div>
//             </div>
//           </section>
//         </div>
//       }
//     </>
//   );
// };

// export default Sidebar;


import React from "react";
import { Link } from "react-router-dom";
import "remixicon/fonts/remixicon.css"; // Import remix icon stylesheet

const Sidebar = ({
  categoryData,
  selectedSubcategory,
  setSelectedSubcategory,
  selectedCategory,
  setSelectedCategory,
  isSideBarOpen,
  setIsSideBarOpen,
  handleCategoryClick,
  handleSubcategoryClick,
  expandedCategories,
  reset
}) => {
  return (
    <>
      <div className="max-h-full grow overflow-hidden">
        <div className="max-h-screen w-full p-5">
          <div className="flex items-center justify-between lg:mb-2 xl:mb-0">
            <h3 className="font-bold">Category Filter</h3>
            {(selectedCategory || selectedSubcategory) && (
              <div onClick={reset} className="text-primary text-sm font-semibold cursor-pointer">
                Reset
              </div>
            )}
          </div>
          <ul className="text-xs xl:py-4">
            {categoryData?.map((category) => (
              <li key={category.id} className="rounded-md py-1">
                <button
                  className={`flex w-full justify-between items-center py-2 font-semibold text-sm transition-all ease-in-out duration-300 ${selectedCategory?.id === category.id ? "text-primary" : ""}`}
                  onClick={() => handleCategoryClick(category)}
                >
                  <span>{category.categoryName}</span>
                  <i
                    className={`ri-arrow-${expandedCategories === category.id ? "up" : "down"}-s-line transition-transform duration-300 ease-in-out`}
                  ></i>
                </button>
                <ul
                  className={`overflow-hidden transition-all duration-300 ease-in-out ${expandedCategories === category.id ? "max-h-40 opacity-100" : "max-h-0 opacity-0"}`}
                >
                  {category.subCategories.map((subcategory) => (
                    <li key={subcategory.id} className="rounded-md py-1">
                      <button
                        className={`flex items-center py-2 text-sm font-semibold transition-colors ${selectedSubcategory?.subCategoryName === subcategory.subCategoryName ? "text-primary" : ""}`}
                        onClick={() => handleSubcategoryClick(subcategory)}
                      >
                        <span>{subcategory.subCategoryName}</span>
                      </button>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Mobile Sidebar Overlay */}
      <div
        className={`fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ${isSideBarOpen ? "opacity-100" : "opacity-0 pointer-events-none"}`}
      >
        <section
          className={`fixed top-0 left-0 z-50 h-full max-w-md bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${isSideBarOpen ? "translate-x-0" : "-translate-x-full"}`}
        >
          <div className="flex flex-col h-full">
            <div className="fixed top-0 w-full flex items-center justify-between border-b border-gray-200 bg-white p-5">
              <Link to="/" className="flex items-center">
                <h1 className="text-lg font-semibold text-primary">Efactura</h1>
              </Link>
              <button
                onClick={() => setIsSideBarOpen(false)}
                className="flex items-center justify-center rounded-full bg-gray-200 h-7 w-7 hover:bg-primary hover:text-white transition-all duration-200"
              >
                <i className="ri-close-line"></i>
              </button>
            </div>
            <div className="pt-20 px-5">
              <ul className="text-xs xl:py-8">
                {categoryData?.map((category) => (
                  <li key={category.id} className="rounded-md py-1">
                    <button
                      className={`flex w-full justify-between items-center py-2 font-semibold text-sm transition-all ease-in-out duration-300 ${selectedCategory?.id === category.id ? "text-primary" : ""}`}
                      onClick={() => handleCategoryClick(category)}
                    >
                      <span>{category.categoryName}</span>
                      <i
                        className={`ri-arrow-${expandedCategories === category.id ? "up" : "down"}-s-line transition-transform duration-300 ease-in-out`}
                      ></i>
                    </button>
                    <ul
                      className={`overflow-hidden transition-all duration-300 ease-in-out ${expandedCategories === category.id ? "max-h-40 opacity-100" : "max-h-0 opacity-0"}`}
                    >
                      {category.subCategories.map((subcategory) => (
                        <li key={subcategory.id} className="rounded-md py-1 ml-4">
                          <button
                            className={`flex items-center py-2 text-sm font-semibold transition-colors ${selectedSubcategory?.subCategoryName === subcategory.subCategoryName ? "text-primary" : ""}`}
                            onClick={() => handleSubcategoryClick(subcategory)}
                          >
                            <span>{subcategory.subCategoryName}</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Sidebar;

