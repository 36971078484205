// import { Spin } from 'antd';

// const GlobalLoader = () => {
//     return (
//         <div style={{
//             height: '100%',
//             position: 'fixed',
//             display: 'flex',
//             justifyContent: 'center',
//             top: '50%',
//             opacity: .25,
//             alignItems: 'center',
//             left: '50%',
//             width: '100%',
//             background: '#000',
//             zIndex: 40,
//             transform: 'translate(-50%, -50%)',
//             textAlign: 'center'
//         }} className=''>
//             <Spin size="large" />
//         </div>
//     );
// };

// export default GlobalLoader;

import React from 'react';

const GlobalLoader = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
      <div className="relative">
        {/* Outer circle */}
        <div className="w-24 h-24 rounded-full border-8 border-gray-200"></div>
        
        {/* Spinning segment */}
        <div className="absolute top-0 left-0 w-24 h-24">
          <div className="w-24 h-24 rounded-full border-8 border-transparent border-t-primary animate-spin"></div>
        </div>
        
        {/* Loading text */}
        <div className="absolute inset-0 flex items-center justify-center p-2">
          <span className="text-white font-semibold text-sm">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default GlobalLoader;