import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useDispatch, useSelector } from "react-redux";
import CartDrawer from "../../components/CartDrawer";
import { editCartQuantity, fetchCartItems, fetchTempCartItems, handleDeleteCartItems } from "../../services/products.service";
import { setOpenCart } from "../../redux/reducers/products";
import { ShoppingBag } from "../../assets/cart/svg";

const CartButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { makeRequest } = useAxios();
  const { cartData, tempCartData, openCart } = useSelector((state) => state.products);
  const { isLoggedIn } = useSelector((state) => state.authSlice);
  const uuid = localStorage?.getItem('uuid')
  const deletCartItems = (itemId) => {
    handleDeleteCartItems(
      makeRequest,
      dispatch,
      isLoggedIn,
      cartData?.data?.id,
      tempCartData?.data?.id,
      itemId,
      uuid
    );
  };

  useEffect(() => {
    if (openCart) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Clean up on component unmount
    return () => document.body.classList.remove("overflow-hidden");
  }, [openCart]);

  const handleToggleCart=(bool)=>{
    dispatch(setOpenCart(bool))
  }

  const totalItems =
    (isLoggedIn ? cartData : tempCartData)?.data?.items?.reduce(
      (acc, item) => acc + item.quantity,
      0
    ) || 0;

const handleEditCartQuantity =(item, action)=>{
  const uuid = localStorage?.getItem('uuid')
  editCartQuantity(
    makeRequest,
    dispatch,
    isLoggedIn,
    item,
    action,
    cartData?.data?.storeId,
    tempCartData?.data?.storeId,
    cartData?.data?.id,
    tempCartData?.data?.id,
    uuid
  )
}

  const handleCheckout = () => {
    navigate(isLoggedIn ? "/checkout" : "/signin");
    handleToggleCart(false);
  };

  // function uuidv4() {
  //   return "10000000-1000-4000".replace(/[018]/g, c =>
  //     (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
  //   );
  // }
  function uuidv4(length = 20) {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const array = new Uint8Array(length);
    crypto.getRandomValues(array);
    for (let i = 0; i < length; i++) {
      result += characters[array[i] % characters.length];
    }
    return result;
  }

  useEffect(() => {
    const uuid = localStorage?.getItem('uuid');
    if (isLoggedIn) {
      fetchCartItems(makeRequest, dispatch);
    } else {
      if(uuid){
        fetchTempCartItems(makeRequest, dispatch, uuid);
      }else{
        localStorage?.setItem('uuid', uuidv4());
        fetchTempCartItems(makeRequest, dispatch, uuidv4());
      }
    }
  }, []);

  return (
    <div className={`${openCart ? 'block' : 'hidden'} lg:block`}>
      {(isLoggedIn ? cartData : tempCartData)?.data?.items.length > 0 && (
        <button
          onClick={() => handleToggleCart(!openCart)}
          className="fixed top-1/2 right-0 z-40 -mt-12 flex flex-col items-center justify-center rounded bg-primary p-3 pt-3.5 text-sm font-semibold text-white shadow-900 transition-colors duration-200 hover:bg-primary-hover focus:outline-0 rounded-tr-none rounded-br-none lg:flex"
        >
          <span className="flex pb-0.5">
            <ShoppingBag/>
            <span className="flex ml-2">{totalItems || 0} Items</span>
          </span>
          <span className="w-full px-2 py-2 mt-3 rounded bg-white text-primary">
            ${(isLoggedIn ? cartData : tempCartData)?.data?.totalCartValue || 0}
          </span>
        </button>
      )}
      {openCart && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-10 p-4 sm:p-0">
          {/* Overlay for background opacity */}
          <div
            onClick={() => handleToggleCart(false)}
            className="absolute inset-0 bg-black opacity-[0.5]"
          ></div>
          <CartDrawer
            isOpen={openCart}
            setOpen={handleToggleCart}
            cartData={isLoggedIn ? cartData : tempCartData}
            handleEditCartQuantity={handleEditCartQuantity}
            handleDeleteCartItems={deletCartItems}
            handleCheckout={handleCheckout}
          />
        </div>
      )}
    </div>
  );
};

export default CartButton;
