
import Navbar from '../components/shared/Navbar';
import Footer from '../components/shared/Footer';
import useAuthRedirect from '../hooks/authRedirect';
import {  Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GlobalLoader from '../components/shared/GlobalLoader';
import { ToastContainer } from 'react-toastify';
import CartButton from '../screens/ShopPanel/CartButton';
import useInactivityLogout from '../hooks/logoutHook';
import { useEffect } from 'react';


// eslint-disable-next-line react/prop-types
const Layout = () => {
    const { loading } = useSelector((state) => state.authSlice);
    const location = useLocation();
    const navigate = useNavigate();
    // useAuthRedirect();
    // useInactivityLogout();
    // const { isLoggedIn } = useSelector((state) => state.authSlice);

    // useEffect(() => {
    //     if(isLoggedIn){
    //         navigate('all-shops')
    //     }else{
    //         navigate('/auth')
    //     }
    // }, [])
    

    return (
        <>
            {
                loading && <GlobalLoader />
            }
            <section style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                {/* {(location.pathname === '/signin' || location.pathname === '/signup') ? null : <Navbar />} */}
                <Navbar />
                <div className='flex-grow'>
                    <ToastContainer />
                    {
                        (location.pathname === '/signin' || location.pathname === '/signup' || location.pathname === '/checkout') ? null : <CartButton />
                    }
                    <Outlet />
                </div>
                {(location.pathname === '/signin' || location.pathname === '/signup' || location.pathname === '/landing-page') ? null : <Footer style={{ marginTop: 'auto' }} />}
            </section>
        </>
    );
};

export default Layout;
