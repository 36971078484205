import React, { useEffect, useState } from "react";
import dummyImg from "../assets/images/dummy.jpg"
import ProductCard from "./ProductCard";
import useAxios from "../hooks/useAxios";
import { useDispatch, useSelector } from "react-redux";
import { setQuantity , setCartData, setTempCartData } from "../redux/reducers/products";
import { ADD_CART, DELETE_CART_ITEMS, FETCH_CART_ITEMS, FETCH_PRODUCTBYID, FETCH_RELATED_PRODCUTS, TEMP_CART } from "../contstants/apis";
import { fetchCartItems, fetchProducts, fetchTempCartItems, handleDeleteCartItems, updateCart } from "../services/products.service";

const ProductDetail = ({
  productId,
  onCancel,
  storeId,
}) => {
  const [productDetails, setProductDetails] = useState(null);
  const [productData, setProductData] = useState([]);
  const [localQuantity , setLocalQuantity] = useState(0)
  const {cartData} = useSelector((state)=> state.products)
  const {tempCartData} = useSelector((state)=> state.products)
  const { makeRequest } = useAxios();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.authSlice);
  const uuid = localStorage?.getItem('uuid')

  console.log('tempCartData product details',tempCartData);
  
  const getProductQuantity = (productId) => {
    const cartItem = (isLoggedIn ? cartData : tempCartData)?.data?.items.find(
      (item) => item.productId === productId
    );
    return cartItem ? cartItem.quantity : 0;
  };

  useEffect(() => {
    setLocalQuantity(getProductQuantity(productId));
  }, [cartData, tempCartData]);

  const updateSuccessCb = () => {
    if (isLoggedIn) {
      fetchCartItems(makeRequest, dispatch);
    } else {
      fetchTempCartItems(makeRequest, dispatch, uuid);
    }
  };

  const handleUpdateCart = (newQuantity) => {
    updateCart(
      makeRequest,
      isLoggedIn,
      newQuantity,
      productId,
      storeId,
      updateSuccessCb,
      uuid
    );
  };

  const handleAddToCart = () => {
    const initialQuantity = 1;
    setLocalQuantity(initialQuantity);
    dispatch(setQuantity(initialQuantity));
    handleUpdateCart(initialQuantity);
  };

  const handleIncreaseQuantity = () => {
    const newQuantity = localQuantity + 1;
    setLocalQuantity(newQuantity);
    dispatch(setQuantity(newQuantity));
    handleUpdateCart(newQuantity);
  };

  const handleDecreaseQuantity = () => {
    const newQuantity = Math.max(localQuantity - 1, 0);
    setLocalQuantity(newQuantity);
    dispatch(setQuantity(newQuantity));
    if (newQuantity > 0) {
      handleUpdateCart(newQuantity);
    } else {
      handleDeleteCartItems(
        makeRequest,
        dispatch,
        isLoggedIn,
        cartData?.data?.id,
        tempCartData?.data?.id,
        productId,
        uuid
      );
    }
  };

  useEffect(() => {
    fetchProducts(makeRequest, productId, storeId, setProductDetails);
    // fetchRelatedProducts();
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden bg-black bg-opacity-50">
      <div className="relative w-full max-w-6xl max-h-[90vh] bg-white rounded-xl overflow-hidden">
        <div className="absolute top-4 right-4 z-[60]">
          <button
            onClick={onCancel}
            className="inline-flex items-center justify-center w-8 h-8 rounded-full bg-white text-gray-600 hover:bg-gray-100 focus:outline-none"
          >
            <i className="ri-close-line ri-2x"></i>
          </button>
        </div>
        <div className="overflow-y-auto hide-scrollbar max-h-[90vh]">
          {/* short details of products */}
          <article className="bg-white">
            <div className="flex flex-col md:flex-row">
              <div className="p-6 pt-10 md:w-1/2 lg:p-14 xl:p-16">
                <div className="product-gallery h-full">
                  <img
                    src={productDetails?.image || dummyImg}
                    alt="Product"
                    className="w-full h-auto object-cover"
                  />
                </div>
              </div>
              <div className="flex flex-col items-start p-5 pt-10 md:w-1/2 lg:p-14 xl:p-16">
                <div className="w-full">
                  <div className="flex w-full items-start justify-between space-x-8 rtl:space-x-reverse">
                    <h1 className="text-lg font-semibold tracking-tight text-gray-800 md:text-xl xl:text-2xl">
                      {productDetails?.name}
                    </h1>
                  </div>
                  
                  <div className="mt-6 flex items-center">
                    <span className="text-2xl font-semibold text-gray-900">
                      ${productDetails?.price}
                    </span>
                  </div>
                  <div className="mt-6 flex flex-col items-center md:flex-row">
                    <div className="mb-3 w-full lg:mb-0 lg:max-w-[250px]">
                      {localQuantity === 0 ? (
                        <button
                        onClick={handleAddToCart}
                         className="w-full flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary hover:bg-primary-hover">
                          Add to Cart
                        </button>
                      ) : (
                        <div className="overflow-hidden bg-primary w-full h-14 rounded text-light bg-primary inline-flex justify-between">
                          <button
                          onClick={handleDecreaseQuantity}
                            className="cursor-pointer p-2 transition-colors duration-200 hover:bg-primary-hover focus:outline-0 px-5"
                          >
                            <span className="sr-only">minus</span>
                            <i className="ri-subtract-line text-white text-xl"></i>
                          </button>
                          <div className="flex text-white flex-1 items-center justify-center px-3 text-sm font-semibold">
                            {localQuantity}
                          </div>
                          <button
                          onClick={handleIncreaseQuantity}
                            className="cursor-pointer p-2 transition-colors duration-200 hover:bg-primary-hover focus:outline-0 px-5"
                          >
                            <span className="sr-only">plus</span>
                            <i className="ri-add-line text-white text-xl"></i>
                          </button>
                        </div>
                      )}
                    </div>
                   
                  </div>
                </div>
                <div className="mt-8 w-full border-t border-gray-200 pt-8">
                  <h3 className="text-sm font-medium text-gray-900">
                    Categories
                  </h3>
                  <div className="mt-2 flex flex-wrap gap-2">
                    <span className="inline-flex items-center rounded-full bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800">
                      {productDetails?.category}
                    </span>
                  </div>
                </div>
                <div className="mt-8 w-full">
                  <h3 className="text-sm font-medium text-gray-900">
                    Sub Category
                  </h3>
                  <p className="mt-1 text-sm text-primary hover:text-primary-hover">
                    {productDetails?.subCategory}
                  </p>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
